function fadeOutIntroScreen() {

  var introScreen = document.getElementById('intro-screen')
  var textLogo = document.getElementById('homepage-logo')
  
  setTimeout(() => {
    introScreen.classList.add('opacity-0');
    textLogo.classList.add('opacity-100')

    introScreen.addEventListener('transitionend', () => {
      introScreen.classList.remove('z-20');
      textLogo.classList.remove('opacity-0');
      introScreen.classList.add('hidden');
    });
  }, 1000);
}

function showNavBar(openButton) {
  var textLogo = document.getElementById('homepage-logo')
  var sideNav = document.getElementById('sidebar-nav')
  if (textLogo) {
    textLogo.classList.add('hidden')
  }
  sideNav.classList.remove('-translate-x-full')
  sideNav.classList.add('translate-x-0')
  openButton.classList.add('hidden')
}

function hideNavBar(openButton) {
  var textLogo = document.getElementById('homepage-logo')
  var sideNav = document.getElementById('sidebar-nav')
  var mainContent = document.getElementById('main-content')
  if (textLogo) {
    textLogo.classList.remove('hidden')
  }
  sideNav.classList.remove('translate-x-0')
  sideNav.classList.add('-translate-x-full')
  mainContent.classList.remove('md:col-span-10')
  mainContent.classList.add('col-span-12')
  openButton.classList.remove('hidden')
}


export {fadeOutIntroScreen, showNavBar, hideNavBar }